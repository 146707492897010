<template>
  <div class="login_wrap">
    <form id="loginForm" name="loginForm" action="/auth" method="post">
      <input type="hidden" name="_token" value="IJdk6YWtHtWcMnb4oyOgVOlUwSXo22cIKXGi82jG" />
      <img class="logo" src="@/assets/images/logo.png" />
      <div class="login">
        <h1>밝음 행복심리센터 CRM Login</h1>
        <div class="login_con">
          <input type="email" id="login_email" name="email" v-model="email" placeholder="ID (e-mail)" /><br />

          <input type="password" id="login_password" name="password" v-model="password" placeholder="password" @keyup.enter="login()" /><br />
        </div>
        <div class="login_save"><input type="checkbox" v-model="isSavedEmail" /> 아이디 저장</div>
        <a class="btn_login pointer" @click="login()">로그인</a>
        <div class="login_href">
          <a @click="moveRegCounselor()">협약센터 상담사 등록</a>
          <a @click="moveRegCenter()" class="ml-30">협약센터 관리자 등록</a>
        </div>
      </div>
    </form>
    <div />
    <div />
    <div />
  </div>
</template>

<script>
import { EmptyCheck } from "@/utils/validation.js";
import { mapActions } from "vuex";
export default {
  data: () => ({
    email: "", // 아이디
    password: "", // 비밀번호
    isSavedEmail: true, // 아이디 저장 체크 여부
  }),

  mounted() {
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      alert("해당 서비스는 IE를 지원하지 않으며 Chrome, Edge, 웨일에 최적화되어 있습니다.");
      location.href = "https://www.google.co.kr/chrome/";
    }

    // 아이디 저장 체크되있는 경우 아이디 가져오기
    if (localStorage.getItem("loginEmail")) {
      this.isSavedEmail = true;
      this.email = localStorage.getItem("loginEmail");
      document.getElementById("login_password").focus();
    } else {
      document.getElementById("login_email").focus();
    }
  },

  methods: {
    ...mapActions(["crmLogin"]),
    // 로그인
    login() {
      // 이메일 유효성검사
      if (!EmptyCheck(this.email, "이메일을")) {
        return false;
      }

      // 비밀번호 유효성검사
      if (!EmptyCheck(this.password, "비밀번호를")) {
        return false;
      }

      // 아이디 저장 체크시 localStorage에 저장
      if (this.isSavedEmail) {
        localStorage.setItem("loginEmail", this.email);
      } else {
        localStorage.removeItem("loginEmail", this.email);
      }

      var params = {
        id: this.email,
        password: this.password,
      };

      this.crmLogin(params);
    },

    // 상담사 등록요청 페이지로 이동
    moveRegCounselor() {
      this.$router.push("/regCounselor");
    },

    // 협약센터 등록요청 페이지로 이동
    moveRegCenter() {
      this.$router.push("/regCenter");
    },
  },
};
</script>
